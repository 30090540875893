import constants from "./constants.js";
const cdn = constants.CDN;

const rawProductions = [
	{
		id: "B1",
		type: "Beat",
		contents: "1 beat",
		title: "Damn it All to Hell",
		description: "",
		formatInfo: "44.1 kHz WAV",
		stemsIncluded: true,
		basename: "B1-damn-it-all-to-hell",
		date: new Date(),
		tracks: ["B1-1"],
		tags: ["Rap", "Toronto", "2021"],
		producers: ["Tom Sinclair"],
		coverArtists: ["Piotr Siedlecki"],
		licenses: ["beat-1.0"],
		defaultLicense: "beat-1.0",
		isPublic: false,
	},
	{
		id: "B2",
		type: "Beat",
		contents: "1 beat",
		title: "Tiger Claws",
		description: "",
		formatInfo: "44.1 kHz WAV",
		stemsIncluded: true,
		basename: "B2-tiger-claws",
		date: new Date(),
		tracks: ["B2-1"],
		tags: ["Rap", "Houston", "2021"],
		producers: ["Tom Sinclair"],
		coverArtists: ["Jean Beaufort"],
		licenses: ["beat-1.0"],
		defaultLicense: "beat-1.0",
		isPublic: false,
	},
	{
		id: "B3",
		type: "Beat",
		contents: "1 beat",
		title: "Rushing",
		description: "",
		formatInfo: "44.1 kHz WAV",
		stemsIncluded: true,
		basename: "B3-rushing",
		date: new Date(),
		tracks: ["B3-1"],
		tags: ["Pop", "Toronto", "2021"],
		producers: ["Tom Sinclair"],
		coverArtists: ["Paul Conley"],
		licenses: ["beat-1.0"],
		defaultLicense: "beat-1.0",
		isPublic: false,
	},
	{
		id: "B4",
		type: "Beat",
		contents: "1 beat",
		title: "Pagan Dance",
		description: "",
		formatInfo: "44.1 kHz WAV",
		stemsIncluded: true,
		basename: "B4-pagan-dance",
		date: new Date(),
		tracks: ["B4-1"],
		tags: ["Rap", "Houston", "2021"],
		producers: ["Tom Sinclair"],
		coverArtists: ["pixabay/contemporaryarts"],
		licenses: ["beat-1.0"],
		defaultLicense: "beat-1.0",
		isPublic: false,
	},
	{
		id: "B5",
		type: "Beat",
		contents: "1 beat",
		title: "6nose",
		description: "",
		formatInfo: "44.1 kHz WAV",
		stemsIncluded: true,
		basename: "B5-6nose",
		date: new Date(),
		tracks: ["B5-1"],
		tags: ["Pop", "London", "2021"],
		producers: ["Tom Sinclair"],
		coverArtists: ["@vox.athena"],
		licenses: ["beat-1.0"],
		defaultLicense: "beat-1.0",
		isPublic: false,
	},
	{
		id: "S1",
		type: "Sample Pack",
		contents: "3 compositions",
		title: "Free Pack 01",
		description:
			"<em>Free Pack 01</em> is a free sample pack from Torcher ranging in styles from 70s soul and disco to modern rap and electronic music. We hope that you find inspiration for your own productions in these musical and sonic ideas.",
		formatInfo: "44.1 kHz WAV",
		stemsIncluded: true,
		basename: "S1-free-pack-01",
		date: new Date(),
		tracks: ["S1-1", "S1-2", "S1-3"],
		tags: [],
		producers: ["Mitchell Paxton", "Tom Sinclair", "Jack Bricker"],
		coverArtists: ["Mihailo Kalabic"],
		licenses: ["free-sample-1.0"],
		defaultLicense: "free-sample-1.0",
		isPublic: true,
	},
	{
		id: "S2",
		type: "Sample Pack",
		contents: "15 compositions",
		title: "Platinum, Guitar and Synthesizer",
		description:
			"<em>Platinum, Guitar and Synthesizer</em> is the first major release from Torcher. The project was conceived to meet the demand of current rap, rnb, and pop producers as they increasingly deliver guitar-driven productions. Created in Atlanta, this pack takes influence from several rap artists and producers in the area. More experimental influences for the work come from artists such as Frank Ocean and Bon Iver. These 15 compositions explore vast sonic territory through the use of guitar and synth tones. At times, these two instruments will meld, becoming indistinguishable from one another. This is intentional.",
		formatInfo: "44.1 kHz WAV",
		stemsIncluded: true,
		basename: "S2-platinum-guitar-and-synthesizer",
		date: new Date(),
		tracks: [
			"S2-01",
			"S2-02",
			"S2-03",
			"S2-04",
			"S2-05",
			"S2-06",
			"S2-07",
			"S2-08",
			"S2-09",
			"S2-10",
			"S2-11",
			"S2-12",
			"S2-13",
			"S2-14",
			"S2-15",
		],
		tags: [],
		producers: ["Tom Sinclair", "Jack Bricker"],
		coverArtists: ["Mihailo Kalabic"],
		licenses: ["sample-1.0"],
		defaultLicense: "sample-1.0",
		isPublic: false,
	},
];

const productions = rawProductions.map((rawProduction, index) => {
	const production = {
		...rawProduction,
		coverUrl128: `${cdn}/${rawProduction.basename}-cover-128.jpeg`,
		coverUrl256: `${cdn}/${rawProduction.basename}-cover-256.jpeg`,
		coverUrl512: `${cdn}/${rawProduction.basename}-cover-512.jpeg`,
		coverUrl1024: `${cdn}/${rawProduction.basename}-cover-1024.jpeg`,
	};
	if (production.isPublic) {
		production.downloadUrl = `${cdn}/${rawProduction.basename}.zip`;
	} else {
		production.downloadUrl = null;
	}
	return production;
});

export const productionsById = new Map(
	productions.map((production) => [production.id, production])
);
export default productions;
