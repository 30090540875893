import constants from "./constants.js";
const cdn = constants.CDN;

const rawTracks = [
	{
		id: "B1-1",
		production: "B1",
		title: "Damn it All to Hell",
		basename: "B1-1-damn-it-all-to-hell",
		producers: ["Tom Sinclair"],
		tags: ["Rap", "Toronto", "2020"],
		duration: 161,
		bpm: 88,
		keySignature: "C minor",
	},
	{
		id: "B2-1",
		production: "B2",
		title: "Tiger Claws",
		date: new Date("August 1, 2020 EST"),
		basename: "B2-1-tiger-claws",
		producers: ["Tom Sinclair"],
		tags: ["Rap", "Houston", "2020"],
		duration: 202,
		bpm: 145,
		keySignature: "C minor",
	},
	{
		id: "B3-1",
		production: "B3",
		title: "Rushing",
		date: new Date("August 1, 2020 EST"),
		basename: "B3-1-rushing",
		producers: ["Tom Sinclair"],
		tags: ["Pop", "Toronto", "2020"],
		duration: 175,
		bpm: 168,
		keySignature: "C minor",
	},
	{
		id: "B4-1",
		production: "B4",
		title: "Pagan Dance",
		date: new Date("August 1, 2020 EST"),
		basename: "B4-1-pagan-dance",
		producers: ["Tom Sinclair"],
		tags: ["Rap", "Houston", "2020"],
		duration: 219,
		bpm: 124,
		keySignature: "C minor",
	},
	{
		id: "B5-1",
		production: "B5",
		title: "6nose",
		date: new Date("August 1, 2020 EST"),
		basename: "B5-1-6nose",
		producers: ["Tom Sinclair"],
		tags: ["Pop", "London", "2020"],
		duration: 118,
		bpm: 116,
		keySignature: "C minor",
	},
	{
		id: "S1-1",
		production: "S1",
		title: "Forrest",
		basename: "S1-1-forrest",
		producers: ["Mitchell Paxton", "Tom Sinclair"],
		tags: [],
		duration: 62,
		bpm: 72,
		keySignature: "C minor",
	},
	{
		id: "S1-2",
		production: "S1",
		title: "Translucent Blue",
		basename: "S1-2-translucent-blue",
		producers: ["Jack Bricker", "Mitchell Paxton", "Tom Sinclair"],
		tags: [],
		duration: 72,
		bpm: 95,
		keySignature: "C minor",
	},
	{
		id: "S1-3",
		production: "S1",
		title: "Two Birds, One Stone",
		basename: "S1-3-two-birds-one-stone",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 58,
		bpm: 114,
		keySignature: "C minor",
	},
	{
		id: "S2-01",
		production: "S2",
		title: "Teeming with Life",
		basename: "S2-01-teeming-with-life",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 68,
		bpm: 110,
		keySignature: "C minor",
	},
	{
		id: "S2-02",
		production: "S2",
		title: "Drift",
		basename: "S2-02-drift",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 81,
		bpm: 148,
		keySignature: "C minor",
	},
	{
		id: "S2-03",
		production: "S2",
		title: "Seamless",
		basename: "S2-03-seamless",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 69,
		bpm: 85,
		keySignature: "C minor",
	},
	{
		id: "S2-04",
		production: "S2",
		title: "Weight of Blood",
		basename: "S2-04-weight-of-blood",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 82,
		bpm: 140,
		keySignature: "C minor",
	},
	{
		id: "S2-05",
		production: "S2",
		title: "Seance",
		basename: "S2-05-seance",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 63,
		bpm: 140,
		keySignature: "C minor",
	},
	{
		id: "S2-06",
		production: "S2",
		title: "Still Life",
		basename: "S2-06-still-life",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 72,
		bpm: 141,
		keySignature: "C minor",
	},
	{
		id: "S2-07",
		production: "S2",
		title: "Shadow Moon",
		basename: "S2-07-shadow-moon",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 74,
		bpm: 156,
		keySignature: "C minor",
	},
	{
		id: "S2-08",
		production: "S2",
		title: "Stanford",
		basename: "S2-08-stanford",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 55,
		bpm: 140,
		keySignature: "C minor",
	},
	{
		id: "S2-09",
		production: "S2",
		title: "Spheres",
		basename: "S2-09-spheres",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 82,
		bpm: 140,
		keySignature: "C minor",
	},
	{
		id: "S2-10",
		production: "S2",
		title: "Majesty",
		basename: "S2-10-majesty",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 78,
		bpm: 150,
		keySignature: "C minor",
	},
	{
		id: "S2-11",
		production: "S2",
		title: "Preguntas",
		basename: "S2-11-preguntas",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 66,
		bpm: 90,
		keySignature: "C minor",
	},
	{
		id: "S2-12",
		production: "S2",
		title: "In the Shell",
		basename: "S2-12-in-the-shell",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 32,
		bpm: 60,
		keySignature: "C minor",
	},
	{
		id: "S2-13",
		production: "S2",
		title: "Automata",
		basename: "S2-13-automata",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 70,
		bpm: 60,
		keySignature: "C minor",
	},
	{
		id: "S2-14",
		production: "S2",
		title: "Will of Man",
		basename: "S2-14-will-of-man",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 74,
		bpm: 82,
		keySignature: "C minor",
	},
	{
		id: "S2-15",
		production: "S2",
		title: "Farsighted",
		basename: "S2-15-farsighted",
		producers: ["Tom Sinclair"],
		tags: [],
		duration: 106,
		bpm: 75,
		keySignature: "C minor",
	},
];

const tracks = rawTracks.map((track, index) => ({
	...track,
	previous: rawTracks[(index + rawTracks.length - 1) % rawTracks.length].id,
	next: rawTracks[(index + 1) % rawTracks.length].id,
	lossy: [
		{
			url: `${cdn}/${track.basename}-master.ogg`,
			mime: "audio/ogg",
		},
		{
			url: `${cdn}/${track.basename}-master.aac`,
			mime: "audio/aac",
		},
	],
}));

export const tracksById = new Map(tracks.map((track) => [track.id, track]));
export default tracks;
